<template>
  <div>
    <!--      Section: Header      -->
    <div
      class="d-flex"
      style="column-gap: .5rem"
    >
      <!--      Add      -->
      <b-button
        v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        size="sm"
        variant="primary"
        @click="openModalAddMicroTask()"
      >
        {{ $t('Add') }}

        <feather-icon icon="PlusIcon" />
      </b-button>

      <!--      Refresh      -->
      <b-button
        variant="primary"
        class="btn-icon"
        size="sm"
        @click="loadMicroTasks()"
      >
        <feather-icon
          icon="RefreshCwIcon"
          class="m-0"
        />
      </b-button>
    </div>

    <!--    Section: Filters    -->
    <filters
      is-task
      is-micro
      :project-id="Number($route.params.project)"
      :task-id="Number($route.params.task)"
      :start-filters="startFilters"
      @change="fl => {
        filtersList = fl

        loadMicroTasks()
      }"
    />

    <!--      Section: Body      -->
    <b-card
      class="overflow-y-scroll mt-1"
      style="max-height: 80vh"
    >
      <template v-if="taskList.length">
        <micro-task
          v-for="(task, i) in taskList"
          :key="i"
          :task="task"
          :is-loading="isLoading"
          is-edit
          @done="doneMicroTask"
          @remove="removeMicroTask"
        />
      </template>

      <div
        v-else-if="isLoading"
        class="text-center py-1 font-weight-bold w-100 text-primary"
      >
        <b-spinner
          small
          variant="primary"
        />

        {{ $t('Loading') }}
      </div>

      <div
        v-else
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
    </b-card>
  </div>
</template>

<script>
import MicroTask from '@/pages/projects/MicroTasks/components/Item.vue'
import {
  CREATE_MICRO_TASK,
  GET_MICRO_TASKS,
  OPEN_MODAL,
  REMOVE_MICRO_TASK,
  UPDATE_MICRO_TASK,
} from '@/@constants/mutations'
import Filters from '@/pages/projects/components/list/Filters.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    MicroTask,
    Filters,
  },
  props: {
    startFilters: {
      type: Object,
      required: false,
      default: () => ({ assignedUsers: [JSON.parse(localStorage.getItem('user'))] }),
    },
  },
  data: () => ({
    isLoading: false,

    taskList: [],

    filtersList: {},
  }),
  computed: {
    ...mapGetters({
      modalState: ['modal/getModalState'],
    }),
  },
  watch: {
    modalState: {
      deep: true,
      handler(n) { if (n.reloadContent) this.loadMicroTasks() },
    },
  },
  methods: {
    ini() { this.loadMicroTasks() },

    loadMicroTasks() {
      this.isLoading = true

      const filters = this.filtersList

      this.$store.dispatch(`microTasks/${GET_MICRO_TASKS}`, filters)
        .then(res => {
          this.taskList = res

          this.taskList.sort((a, b) => (b.doneType === null) - (a.doneType === null) || a.name - b.name)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    openModalAddMicroTask() {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addMicroTaskModal',
          size: 'md',
          modalTitle: this.$i18n.t('MicroTask'),
          okButtonTitle: '',
        })
    },

    removeMicroTask(taskToDelete) {
      const { id, projectTask } = taskToDelete

      if (id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            this.isLoading = true

            const payload = {
              id,
              project: projectTask.project.id,
              projectTask: projectTask.id,
            }

            this.$store.dispatch(`microTasks/${REMOVE_MICRO_TASK}`, payload)
              .then(() => {
                this.taskList.splice(this.taskList.findIndex(task => task.id === id), 1)
              })

              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })

              .finally(() => {
                this.isLoading = false
              })
          })
      }
    },

    doneMicroTask({ task, type }) {
      const payload = {
        id: task.id,
        project: task.projectTask.project.id,
        projectTask: task.projectTask.id,
        doneType: type,
        doneAt: moment(new Date()).format('DD.MM.YYYY hh:mm:ss'),
        doneBy: this.$store.getters['auth/getCurrentUser'].id,
      }

      if (type === null) {
        payload.doneAt = null
        payload.doneBy = null
      }

      if (task.doneType !== payload.doneType) this.send(payload)
    },

    send(payload) {
      this.isLoading = true

      const action = payload?.id ? UPDATE_MICRO_TASK : CREATE_MICRO_TASK

      this.$store.dispatch(`microTasks/${action}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t(payload?.id ? 'MicroTaskUpdated' : 'MicroTaskAdded'))

          if (payload.id) {
            const item = this.taskList.find(task => task.id === payload.id)

            item.doneType = payload.doneType
            item.doneBy = payload.doneBy?.name ? payload.doneBy : this.$store.getters['auth/getCurrentUser']
            item.doneAt = { date: payload.doneAt }
          }

          this.taskList.sort((a, b) => (b.doneType === null) - (a.doneType === null) || a.name - b.name)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },
  },
}
</script>
